import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Input, ValidationSummary } from '@/components/ui/Form'
import { Button, Modal, Typography } from '@/components/ui'
import t from 'helpers/translation/getTranslation'

import { PasswordConfirmModalProps, PasswordConfirmModalData } from './types'
import s from './styles.module.css'


const PasswordConfirmModal = (props: PasswordConfirmModalProps) => {
  const { onSubmit, onCancel, open, error, title, content, loading } = props

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      confirm_password: "",
    },
    mode: "onBlur"
  })

  const handlePasswordSubmit = (data: PasswordConfirmModalData) => {
    if (!data.confirm_password || data.confirm_password.length === 0)
      return

    if (onSubmit) onSubmit(data.confirm_password)

    reset()
  }

  const handleCancel = () => {
    reset()

    if (onCancel) onCancel()
  }

  return (
    <>
      <Modal
        size="md"
        open={open}
        showCloseButton={false}
        canCloseOnClickOutside={false}>
        <>
          <Typography
            as="h3"
            className={s.popup_message}>
            {title}
          </Typography>
          <Typography
            as="div"
            className={s.popup_message}>
            {content}
          </Typography>
          <form onSubmit={handleSubmit(handlePasswordSubmit)}>
            { /* First input is a hack to stop Google autofilling */}
            <Input
              tabIndex={-1}
              className={s.popup_hidden_input}
              autoComplete="off"
              name="password"
              type="password" />
            <Input
              {...register("confirm_password", {
                required: true
              })}
              autoComplete="new_password"
              placeholder={t("components.passwordConfirmModal.enterPassword")}
              type="password" />
            <ValidationSummary
              compact
              className={s.popup_error}
              errors={error ? { root: { message: error } } : {}} />
            <div className={s.popup_actions}>
              <Button
                isLoading={loading}
                type="submit"
                filled
                variant="primary">
                {t("components.passwordConfirmModal.submit")}
              </Button>
              <Button
                onClick={handleCancel}
                variant="default"
                outline>
                {t("components.passwordConfirmModal.cancel")}
              </Button>
            </div>
          </form>
        </>
      </Modal>
    </>
  )
}

export default PasswordConfirmModal