import {
    API_AZURE_MARKETPLACE_GET_SALE_OVERVIEW_STATS,
    API_MARKETPLACE_PRE_ALPHA_ACCESS_PAYMENT_ORDER_CREATE,
    API_MARKETPLACE_PRE_ALPHA_ACCESS_PAYMENT_ORDER_STATUS,
} from 'consts/apiRoutes'
import {
    AzureMarketplaceSaleOverviewStatsResponse,
    MarketplacePreAlphaCreatePaymentResponse,
    MarketplacePreAlphaCreatePaymentRequest,
    MarketplacePreAlphaPaymentStatusResponse,
} from '@/types/marketplace'

import HttpClientBase from './HttpClientBase'

class MarketplaceHttpClient extends HttpClientBase {
    static setAccessToken(accessToken?: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetSaleOverviewStats(urlRoute: string) {
        return this.request<AzureMarketplaceSaleOverviewStatsResponse>({
            url: API_AZURE_MARKETPLACE_GET_SALE_OVERVIEW_STATS,
            method: 'POST',
            data: {
                UrlRoute: urlRoute,
            },
        })
    }

    static async CreatePreAlphaAccessPayment(payload: MarketplacePreAlphaCreatePaymentRequest) {
        return this.request2<MarketplacePreAlphaCreatePaymentResponse>({
            url: API_MARKETPLACE_PRE_ALPHA_ACCESS_PAYMENT_ORDER_CREATE(payload.sub),
            data: {
                email: payload.email,
            },
            method: 'POST',
        })
    }

    static async GetPreAlphaAccessPaymentStatus(sub: string, paymentId: string) {
        return this.request2<string>({
            url: API_MARKETPLACE_PRE_ALPHA_ACCESS_PAYMENT_ORDER_STATUS(sub, paymentId),
            method: 'GET',
        })
    }
}

export default MarketplaceHttpClient
