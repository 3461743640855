import React, { useContext } from 'react'
import cx from 'classnames'
import dynamic from 'next/dynamic'

import { SiteContext } from '../../../../providers/SiteProvider'
import MegaMenuProvider from '../../../ui/Navigation/MegaMenu'
import Breadcrumbs from '../../../ui/Breadcrumbs'
import NavigationProvider from '../../../ui/Navigation'
import Announcements from '../../../ui/Announcements'
import Title from '../../../website/Title'
import Container from '../../../ui/Container'
import Footer from '../../Footer'
import Header from '../../Header'
import Sidebar from '../../Sidebar'

import s from './styles.module.css'
import { LayoutProps } from './types'

// Dynamically load this because we randomly select an image, when this happens SSR does not match frontend.
const PageBackground = dynamic(() => import('../../../ui/PageBackground'), {
  ssr: false,
})

const DEFAULT_BACKGROUND_IMAGES = [
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/BOc3vxd9QDGEiXW3RpVS",
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/ADWvmiA8QnecAEYBPz0E",
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/maN1cC5DTeOkjU19ZDDB",
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/jLN1nlwSmyeRsEcYLZC0",
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/c9XEM8gaQjuLYVRI7zrg",
  "https://cdn.filestackcontent.com/output=format:webp/resize=height:1920/SDUmtrMvQOeGkWPihyd3"
]

/**
 * Master layout
 * @param props 
 * @returns 
 *  */
const MasterLayout = (props: LayoutProps) => {
  const { announcements } = useContext(SiteContext)

  const {
    children,
    preContainer,
    postContainer,
    contain = true,
    sidebar,
    sidebarProps,
    title,
    subtitle,
    separator = "|",
    hideAnnouncements,
    background = {
      images: DEFAULT_BACKGROUND_IMAGES
    }
  } = props

  const mainClasses = cx(s.main, sidebar && s.with_navigation)
  const headerClasses = cx(s.page_title, sidebar && s.with_navigation)

  return (
    <div className={s.layout}>
      <div className={`${s.page} z-[1]`}>
        <NavigationProvider>
          <MegaMenuProvider>
            <Header
              accountMenuItemsClassName={s.account_menu}
              className={s.header}
              megaMenuClassName={s.mega_menu} />
          </MegaMenuProvider>
        </NavigationProvider>

        <Breadcrumbs />

        {preContainer}

        <Container contain={contain} className={s.container}>
          <Title
            as="h1"
            title={title}
            separator={separator}
            className={headerClasses}
            subtitle={subtitle} />

          <div className={s.body}>
            {
              sidebar &&
              <Sidebar {...{ ...sidebarProps, className: cx(s.sidebar, sidebarProps?.className) }}>
                {sidebar}
              </Sidebar>
            }

            <main className={mainClasses}>
              {children}
            </main>
          </div>
        </Container>

        {postContainer}

      </div>

      {
        !hideAnnouncements &&
        <Announcements
          announcements={announcements}
          expandButtonClassName={s.expand}
          panelClassName={s.panel} />
      }

      <PageBackground {...background} />

      <Footer className={s.footer} />
    </div>
  )
}

export default MasterLayout
