import buildPath from 'helpers/buildPath'
import { NavigationItems } from 'types/navigation'
import { t } from 'helpers/translation'
import {
    PLAYER_ROLE_AFFILIATE,
    PLAYER_ROLE_CONTENT_CREATOR,
    PLAYER_ROLE_CONTENT_PARTNER,
    PLAYER_ROLE_CORNUCOPIAS_TEAM,
} from './auth'

import {
    ROUTE_ABOUT_GAME_PLAY_YOUR_WAY,
    ROUTE_PHOTO_GALLERY_BASE,
    ROUTE_ROADMAP,
    ROUTE_TEAM,
    ROUTE_ANNOUNCEMENTS,
    ROUTE_VIDEO_GALLERY_BASE,
    ROUTE_BLOG_CATEGORY,
    ROUTE_WEB3_TOKENONMICS,
    ROUTE_WEB3_COPI_TOKEN,
    ROUTE_NFTS,
    ROUTE_TUTORIAL_LIBRARY,
    ROUTE_CONTACT,
    ROUTE_ACCOUNT_OVERVIEW,
    ROUTE_ACCOUNT_GAME_ASSETS,
    ROUTE_ACCOUNT_SETTINGS,
    ROUTE_HOW_TO_PLAY,
    ROUTE_ACCOUNT_CONTENT_CREATOR,
    ROUTE_AZURE_MARKETPLACE_WITH_LOGIN,
    ROUTE_ACCOUNT_MANAGE_NODES,
    ROUTE_ACCOUNT_REWARDS,
    ROUTE_RACING_LEADERBOARD,
    ROUTE_NODES_REWARD_CALCULATOR,
    ROUTE_WEB3_STAKING,
    ROUTE_GET_THE_GAME,
} from './routes'

export const PRIMARY_MENU_OPTIONS = [
    {
        id: 'about-game',
        label: t('navigation.menus.aboutGame'),
        options: [
            {
                label: t('navigation.megamenu.aboutGame.label'),
                description: t('navigation.megamenu.aboutGame.description'),
                href: ROUTE_ABOUT_GAME_PLAY_YOUR_WAY,
            },
            {
                label: t('navigation.megamenu.announcements.label'),
                description: t('navigation.megamenu.announcements.description'),
                href: ROUTE_ANNOUNCEMENTS,
            },
            {
                label: t('navigation.megamenu.team.label'),
                description: t('navigation.megamenu.team.description'),
                href: ROUTE_TEAM,
            },
            {
                label: t('navigation.megamenu.gameLeaderboards.label'),
                description: t('navigation.megamenu.gameLeaderboards.description'),
                href: ROUTE_RACING_LEADERBOARD,
            },
            {
                label: t('navigation.megamenu.gallery.label'),
                description: t('navigation.megamenu.gallery.description'),
                href: ROUTE_PHOTO_GALLERY_BASE,
            },
            {
                label: t('navigation.megamenu.inGameAssets.label'),
                description: t('navigation.megamenu.inGameAssets.description'),
                href: ROUTE_NFTS,
            },
            {
                label: t('navigation.megamenu.gameBlog.label'),
                description: t('navigation.megamenu.gameBlog.description'),
                href: buildPath(ROUTE_BLOG_CATEGORY, 'game'),
            },
            {
                label: t('navigation.megamenu.gameReels.label'),
                description: t('navigation.megamenu.gameReels.description'),
                href: `${ROUTE_VIDEO_GALLERY_BASE}#game-trailers`,
            },
            {
                label: t('navigation.megamenu.creativeAssets.label'),
                description: t('navigation.megamenu.creativeAssets.description'),
                href: 'https://links.cornucopias.io/cal-public',
            },
        ],
    },
    {
        id: 'web3',
        label: t('navigation.menus.web3'),
        image: {
            src: '/_assets/navigation/og-bubblejett-pink.png',
            alt: t('navigation.imageAlts.bubblejett'),
        },
        options: [
            {
                label: t('navigation.megamenu.copiToken.label'),
                description: t('navigation.megamenu.copiToken.description'),
                href: ROUTE_WEB3_COPI_TOKEN,
            },
            {
                label: t('navigation.megamenu.staking.label'),
                description: t('navigation.megamenu.staking.description'),
                href: ROUTE_WEB3_STAKING,
            },
            {
                label: t('navigation.megamenu.nodeRewardCalculator.label'),
                description: t('navigation.megamenu.nodeRewardCalculator.description'),
                href: ROUTE_NODES_REWARD_CALCULATOR,
            },
            {
                label: t('navigation.megamenu.web3Blog.label'),
                description: t('navigation.megamenu.web3Blog.description'),
                href: buildPath(ROUTE_BLOG_CATEGORY, 'web3'),
            },
            {
                label: t('navigation.megamenu.copiWiki.label'),
                description: t('navigation.megamenu.copiWiki.description'),
                href: 'https://copiwiki.cornucopias.io/',
            },
            {
                label: t('navigation.megamenu.tokenomics.label'),
                description: t('navigation.megamenu.tokenomics.description'),
                href: ROUTE_WEB3_TOKENONMICS,
            },
            {
                label: t('navigation.megamenu.videos.label'),
                description: t('navigation.megamenu.videos.description'),
                href: ROUTE_VIDEO_GALLERY_BASE,
            },
        ],
    },
]

export const SECONDARY_MENU_OPTIONS = [
    {
        label: t('navigation.menus.marketplace'),
        href: ROUTE_AZURE_MARKETPLACE_WITH_LOGIN('https://nft.cornucopias.io/nft-sale'),
        prefetch: false,
    },
]

/**
 * Menu options shown in account pages and top navigation dropdown
 */
export const PLAYER_ACCOUNT_MENU_OPTIONS = [
    {
        label: t('navigation.menus.overview'),
        href: ROUTE_ACCOUNT_OVERVIEW,
        showInDropdown: true,
    },
    {
        label: t('navigation.menus.myGameAssets'),
        href: ROUTE_ACCOUNT_GAME_ASSETS,
        showInDropdown: true,
    },
    {
        label: t('navigation.menus.contentCreator'),
        href: ROUTE_ACCOUNT_CONTENT_CREATOR,
        permissions: [
            PLAYER_ROLE_AFFILIATE,
            PLAYER_ROLE_CONTENT_CREATOR,
            PLAYER_ROLE_CONTENT_PARTNER,
            PLAYER_ROLE_CORNUCOPIAS_TEAM,
        ],
        showInDropdown: true,
    },
    {
        label: t('navigation.menus.manageNodes'),
        href: ROUTE_ACCOUNT_MANAGE_NODES,
        showInDropdown: true,
    },
    {
        label: t('navigation.menus.rewards'),
        href: ROUTE_ACCOUNT_REWARDS,
        showInDropdown: true,
    },
    {
        label: t('navigation.menus.settings'),
        href: ROUTE_ACCOUNT_SETTINGS,
        showInDropdown: true,
    },
] as NavigationItems
