import { UseQueryOptions } from '@tanstack/react-query'
import useAuthApi from 'hooks/useAuthApi'

import { PlayerHttpClient } from '../network'
import { PlayerUserType } from '../types'

export const PLAYER_QUERY_KEY = 'player'
export const PLAYER_BALANCE_QUERY_KEY = `${PLAYER_QUERY_KEY}_BALANCE`

export const usePlayerCopiBalance = (refetchInterval: number | false = false) =>
    useAuthApi({
        queryKey: [PLAYER_BALANCE_QUERY_KEY],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserCopiBalance(),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })

export const usePlayerQuery = (
    id: number,
    options?: Omit<UseQueryOptions<PlayerUserType>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [PLAYER_QUERY_KEY, id],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUser(id),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })
