/**
 * NextJS Auth providers ID
 */
export const CREDENTIALS_PROVIDER_ID = 'credentials'
export const MFA_PROVIDER_ID = 'mfa'
export const ERROR_REFRESH_ACCESS_TOKEN = 'RefreshAccessTokenError'

/**
 * Error thrown on login when 2FA is required
 */
export const SIGNIN_MFA_REQUIRED = '2fa_required'

/**
 * SSO login applications
 */
export const AUTH_VALIDATE_SOURCE_LAUNCHER = 'desktop_launcher'
export const AUTH_VALIDATE_SOURCE_AZURE_MARKETPLACE = 'AzureMarketplace'

/**
 * Used to redirect users to the launcher on login
 */
export const COOKIE_AUTH_REDIRECT = 'copi_auth_redirect'

/**
 * Role should match players roles on the admin website
 */
export const PLAYER_ROLE_CORNUCOPIAS_TEAM = 'Cornucopias Team'
export const PLAYER_ROLE_CORNUCOPIAS_QA = 'Cornucopias QA'
export const PLAYER_ROLE_AFFILIATE = 'Affiliate'
export const PLAYER_ROLE_CONTENT_CREATOR = 'Content Creator'
export const PLAYER_ROLE_CONTENT_PARTNER = 'Content Partner'
export const PLAYER_ROLE_EARLY_ACCESS_PASS = 'Pre-Alpha Access Pass'

/**
 * Game components that are required to get access to the game
 */
export const REQUIRED_GAME_COMPONENTS_FOR_GAME_ACCESS = [
    'Calido Valley Resort',
    'Calido Valley Raceway',
]

/**
 * Game components that are required to get access to the launcher
 */
export const REQUIRED_GAME_COMPONENTS_FOR_LAUNCHER = ['Game Launcher']
